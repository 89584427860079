import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import {Link} from 'react-router-dom'
import logo from '../../assets/nav-logo-bl.png'
// import logoIcon from '../../assets/nav-logo-icon.png'
// import nasal from '../../assets/nasal.png'

// import {AiFillCaretDown } from "react-icons/ai";
import Topbar from '../../components/topbar/Topbar';

import './NavbarStyles.css'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)



    return (
        <>
        <Topbar />

        <div className='navbar'>

            <div className='nav-box'>

                <div className="navbar-logo">
                    <Link to='/'>
                            <img src={logo}  alt="Kings TMS" loading="lazy"/>
                    </Link>
                </div>

                <ul className={nav ? 'nav-menu active' : 'nav-menu'}>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/tms-therapy'>TMS Therapy</Link></li>

                    <li><Link to='/about-us'>About Us</Link> </li>
                    <li><Link to='/insurance'>Insurance</Link></li>
                    <li><Link to='/faq'>FAQs</Link></li>
                    <li><Link to='/contact'>Contact Us</Link></li>

                </ul>

                <div className="hamburger" onClick={handleNav}>
                    {!nav ? (<FaBars className='icon' />) : (<FaTimes className='icon' />)}
                </div>

            </div>

        </div>
        </>
    )
   
}

export default Navbar

