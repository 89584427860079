import React from 'react'
import './Hero.css'

// import TMSTech from '../../assets/tms_technician.webp'

const Hero = () => {
    return (
<>        
<div className='hero'>

        <div className="heroContent">

                <div className='contentCaption'>
                    <h1>I'ts time to feel better!</h1>
                    <h2>Creating stability in recovery.</h2>
                </div>  

                <div className='heroImage'>

                    <div className='imageText'>
                        <h1>I'ts time to feel better!</h1>
                        <h2>Creating stability in recovery.</h2>
                    </div>
                </div>


        </div>

</div>
</>

    )
}

export default Hero